import React, { useEffect } from "react"
// atm packages
import { GetStartedInfo } from "@components"
import { SignupLayout } from "@layout"
import { usePageFlow } from "@providers"
import { usePublishWebEvent } from "@services"
import { TRACKING_ATM, URL_SIGNUP_REGISTER } from "@constants"

const Signup = () => {
  const pageFlow = usePageFlow()
  const [publishWebEvent] = usePublishWebEvent()

  useEffect(() => {
    publishWebEvent({ name: TRACKING_ATM.screen_view_get_started })
  }, [])

  return (
    <SignupLayout
      hideJoinDownload
      navBarNoLinks
      leftContent={
        <GetStartedInfo
          isMobile
          onClick={() => {
            pageFlow.navigateNext(URL_SIGNUP_REGISTER)
          }}
        />
      }
      rightContent={<GetStartedInfo />}
    ></SignupLayout>
  )
}

export default Signup
